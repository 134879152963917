import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material"
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { isEmpty, isNil } from "lodash/fp"
import log from "loglevel"
import { useEffect, useRef, useState } from "react"
import { RequestJsonData, RequestsDataColumns } from "./types"
import { confirmRequest, getRequest, getRequestRows } from "./functions"
import { Loading } from "../Loading"
log.setLevel((process.env['LOG_LEVEL'] as log.LogLevelDesc) ?? log.levels.DEBUG)


export const AdminRequests = () => {
  const [selectedRowId, setSelectedRowId] = useState<string>("")
  const [rows, setRows] = useState<RequestsDataColumns[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const [isRowSelection, setIsRowSelection] = useState<boolean>(false)

  useEffect(() => {
    getRequestRows().then((result) => {
      if (!isNil(result)) {
        setRows(result)
      }
    }).catch(log.error)
  }, [])

  const [companyName, setCompanyName] = useState<string>("")
  const [departmentName, setDepartmentName] = useState<string>("")
  const [post, setPost] = useState<string>("")
  const [familyNameKanji, setFamilyNameKanji] = useState<string>("")
  const [familyNameKana, setFamilyNameKana] = useState<string>("")
  const [firstNameKanji, setFirstNameKanji] = useState<string>("")
  const [firstNameKana, setFirstNameKana] = useState<string>("")
  const [industry, setIndustry] = useState<string>("")
  const [phoneNumber, setPhoneNumber] = useState<string>("")
  const [faxNumber, setFaxNumber] = useState<string>("")
  const [mailAddress, setMailAddress] = useState<string>("")
  const [zipCode, setZipCode] = useState<string>("")
  const [prefecture, setPrefecture] = useState<string>("")
  const [address1, setAddress1] = useState<string>("")
  const [address2, setAddress2] = useState<string>("")
  const [address3, setAddress3] = useState<string>("")
  const [questionnair, setQuestionnair] = useState<string>("")
  const [optionalAnswer, setOptionalAnswer] = useState<string>("")
  const [closingDate, setClosingDate] = useState<string>("")
  const [startDate, setStartDate] = useState<string>("")
  const [autoPayment, setAutoPayment] = useState<string>("")

  const companyNameRef = useRef<HTMLInputElement>(null)
  const departmentNameRef = useRef<HTMLInputElement>(null)
  const postRef = useRef<HTMLInputElement>(null)
  const familyNameKanjiRef = useRef<HTMLInputElement>(null)
  const familyNameKanaRef = useRef<HTMLInputElement>(null)
  const firstNameKanjiRef = useRef<HTMLInputElement>(null)
  const firstNameKanaRef = useRef<HTMLInputElement>(null)
  const industryRef = useRef<HTMLInputElement>(null)
  const phoneNumberRef = useRef<HTMLInputElement>(null)
  const faxNumberRef = useRef<HTMLInputElement>(null)
  const mailAddressRef = useRef<HTMLInputElement>(null)
  const zipCodeRef = useRef<HTMLInputElement>(null)
  const prefectureRef = useRef<HTMLInputElement>(null)
  const address1Ref = useRef<HTMLInputElement>(null)
  const address2Ref = useRef<HTMLInputElement>(null)
  const address3Ref = useRef<HTMLInputElement>(null)
  const questionnairRef = useRef<HTMLInputElement>(null)
  const optionalAnswerRef = useRef<HTMLInputElement>(null)
  const closingDateRef = useRef<HTMLInputElement>(null)
  const startDateRef = useRef<HTMLInputElement>(null)
  const autoPaymentRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    getRequestRows().then((result) => {
      if (!isNil(result)) {
        setRows(result)
      }
    }).catch(log.error)
  }, [])

  const handleChangeCompanyName = () => {
    setCompanyName(companyNameRef.current?.value ?? "")
  }
  const handleChangeDepartmentName = () => {
    setDepartmentName(departmentNameRef.current?.value ?? "")
  }
  const handleChangePost = () => {
    setPost(postRef.current?.value ?? "")
  }
  const handleChangeFamilyNameKanji = () => {
    setFamilyNameKanji(familyNameKanjiRef.current?.value ?? "")
  }
  const handleChangeFamilyNameKana = () => {
    setFamilyNameKana(familyNameKanaRef.current?.value ?? "")
  }
  const handleChangeFirstNameKanji = () => {
    setFirstNameKanji(firstNameKanaRef.current?.value ?? "")
  }
  const handleChangeFirstNameKana = () => {
    setFirstNameKana(firstNameKanjiRef.current?.value ?? "")
  }
  const handleChangeIndustry = () => {
    setIndustry(industryRef.current?.value ?? "")
  }
  const handleChangePhoneNumber = () => {
    setPhoneNumber(phoneNumberRef.current?.value ?? "")
  }
  const handleChangeFaxNumber = () => {
    setFaxNumber(faxNumberRef.current?.value ?? "")
  }
  const handleChangeMailAddress = () => {
    setMailAddress(mailAddressRef.current?.value ?? "")
  }
  const handleChangeZipCode = () => {
    setZipCode(zipCodeRef.current?.value ?? "")
  }
  const handleChangePrefecture = () => {
    setPrefecture(prefectureRef.current?.value ?? "")
  }
  const handleChangeAddress1 = () => {
    setAddress1(address1Ref.current?.value ?? "")
  }
  const handleChangeAddress2 = () => {
    setAddress2(address2Ref.current?.value ?? "")
  }
  const handleChangeAddress3 = () => {
    setAddress3(address3Ref.current?.value ?? "")
  }
  const handleChangeClosingDate = () => {
    setClosingDate(closingDateRef.current?.value ?? "")
  }
  const handleChangeStartDate = () => {
    setStartDate(startDateRef.current?.value ?? "")
  }
  const handleChangeAutoPayment = (event: any) => {
    setAutoPayment(event.target.value)
  }

  const handleDetailClick = async () => {
    if (!isEmpty(selectedRowId)) {
      console.log(selectedRowId)
      getRequest(selectedRowId).then((result: RequestJsonData) => {
        const {
          companyName,
          departmentName,
          post,
          familyNameKanji,
          familyNameKana,
          firstNameKanji,
          firstNameKana,
          industry,
          phoneNumber,
          faxNumber,
          mailAddress,
          zipCode,
          prefecture,
          address1,
          address2,
          address3,
          questionnair,
          optionalAnswer
        } = result
        setCompanyName(companyName)
        setDepartmentName(departmentName)
        setPost(post)
        setFamilyNameKanji(familyNameKanji)
        setFamilyNameKana(familyNameKana)
        setFirstNameKanji(firstNameKanji)
        setFirstNameKana(firstNameKana)
        setIndustry(industry)
        setPhoneNumber(phoneNumber)
        setFaxNumber(faxNumber)
        setMailAddress(mailAddress)
        setZipCode(zipCode)
        setPrefecture(prefecture)
        setAddress1(address1)
        setAddress2(address2)
        setAddress3(address3)
        setQuestionnair(questionnair)
        setOptionalAnswer(optionalAnswer)
      }).catch(log.error)
      setOpen(true)
    }
  }
  const handleClose = () => {
    setOpen(false)
    setSelectedRowId("")
    setIsRowSelection(false)
    setCompanyName("")
  }

  const handleSubmit = async () => {
    confirmRequest(selectedRowId, closingDate, startDate, autoPayment)
      .then((_result) => {
      }).catch((err) => {
        log.error(err)
      })
  }

  const columns: GridColDef[] = [
    { field: 'companyName', headerName: '会社名', width: 150 },
    { field: 'memberId', headerName: '会員ID', width: 300 },
    { field: 'requestAt', headerName: '申込日', width: 200 },
    { field: 'id', headerName: 'ファイル名', width: 400 },
  ]

  const paginationModel = { page: 0, pageSize: 50 }
  return (
    <Box>
      <Typography variant="h6" borderBottom={2} borderColor={"GrayText"} color="gray">申込情報一覧</Typography>
      <Paper sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: { paginationModel },
            sorting: {
              sortModel: [{
                field: 'requestAt',
                sort: 'desc'
              }]
            }
          }}
          pageSizeOptions={[5, 10]}
          sx={{ border: 0 }}
          onRowClick={(event) => {
            setSelectedRowId(event.id.toString())
            setIsRowSelection(true)
          }}
          rowSelection={isRowSelection}
        />
      </Paper>
      <Box display={"flex"} marginTop={3} justifyContent={"center"}>
        <Button variant="contained" onClick={handleDetailClick} sx={{ paddingX: 15 }} disabled={isEmpty(selectedRowId)}>詳細</Button>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            handleClose()
          }
        }}
        fullScreen
      >
        <DialogTitle>お申込詳細</DialogTitle>
        <DialogContent>
          {!isEmpty(companyName) ?
            <Box component="form" sx={{ mt: 1 }}>
              <Stack spacing={3}>
                <TextField id="plan" label="プラン名称" value={"福利厚生プラン"} variant="filled" disabled></TextField>
                <TextField id="corse" label="コース名称" value={"ビジエネライトコース"} variant="filled" disabled></TextField>
                <TextField id="company_name" label="会社名" defaultValue={companyName} required inputRef={companyNameRef} onChange={handleChangeCompanyName}></TextField>
                <TextField id="department_name" label="部署名" defaultValue={departmentName} required inputRef={departmentNameRef} onChange={handleChangeDepartmentName}></TextField>
                <TextField id="post" label="役職名" defaultValue={post} required inputRef={postRef} onChange={handleChangePost}></TextField>
                <TextField id="family_name_kanji" label="担当者姓・漢字" defaultValue={familyNameKanji} required inputRef={familyNameKanjiRef} onChange={handleChangeFamilyNameKanji}></TextField>
                <TextField id="first_name_kanji" label="担当者名・漢字" defaultValue={firstNameKanji} required inputRef={firstNameKanjiRef} onChange={handleChangeFirstNameKanji}></TextField>
                <TextField id="family_name_kana" label="担当者姓・カナ" defaultValue={familyNameKana} required inputRef={familyNameKanaRef} onChange={handleChangeFamilyNameKana}></TextField>
                <TextField id="first_name_kana" label="担当者名・カナ" defaultValue={firstNameKana} required inputRef={firstNameKanaRef} onChange={handleChangeFirstNameKana}></TextField>
                <TextField id="industry" label="業種" defaultValue={industry} required inputRef={industryRef} onChange={handleChangeIndustry}></TextField>
                <TextField id="phone_number" label="連絡先電話番号" defaultValue={phoneNumber} required inputRef={phoneNumberRef} onChange={handleChangePhoneNumber}></TextField>
                <TextField id="fax_number" label="Fax番号" defaultValue={faxNumber} inputRef={faxNumberRef} onChange={handleChangeFaxNumber}></TextField>
                <TextField id="mail_address" label="メールアドレス" defaultValue={mailAddress} required inputRef={mailAddressRef} onChange={handleChangeMailAddress}></TextField>
                <TextField id="zip_code" label="郵便番号" defaultValue={zipCode} inputRef={zipCodeRef} onChange={handleChangeZipCode}></TextField>
                <TextField id="prefecture" label="都道府県" defaultValue={prefecture} required inputRef={prefectureRef} onChange={handleChangePrefecture}></TextField>
                <TextField id="address_1" label="市区町村" defaultValue={address1} required inputRef={address1Ref} onChange={handleChangeAddress1}></TextField>
                <TextField id="address_2" label="番地" defaultValue={address2} required inputRef={address2Ref} onChange={handleChangeAddress2}></TextField>
                <TextField id="address_3" label="アパート・マンション名・号室" defaultValue={address3} inputRef={address3Ref} onChange={handleChangeAddress3}></TextField>

                <FormControl>
                  <InputLabel id="questionnair" required>アンケート</InputLabel>
                  <Select labelId="questionnair" id="select" label="アンケート" defaultValue={questionnair} required inputRef={questionnairRef} disabled variant="filled">
                    <MenuItem value={"none"}></MenuItem>
                    <MenuItem value={"mzc_hp"}>中部電力ミライズコネクトホームページ</MenuItem>
                    <MenuItem value={"bizene_site"}>ビジエネサイト</MenuItem>
                    <MenuItem value={"bizene_mail"}>ビジエネメールマガジン</MenuItem>
                    <MenuItem value={"dm"}>ダイレクトメール</MenuItem>
                    <MenuItem value={"flyer"}>ポスティングチラシ</MenuItem>
                    <MenuItem value={"group_introduction"}>中部電力ミライズグループからの紹介</MenuItem>
                    <MenuItem value={"org_introduction"}>所属団体からの紹介</MenuItem>
                    <MenuItem value={"other"}>その他</MenuItem>
                  </Select>
                </FormControl>
                <TextField id="optional_answer" label="自由回答欄" defaultValue={optionalAnswer} inputRef={optionalAnswerRef} disabled variant="filled"></TextField>
                <TextField id="closing_date" label="締日(10日〆の場合は11を入力してください)" defaultValue={closingDate} inputRef={closingDateRef} onChange={handleChangeClosingDate}></TextField>
                <TextField id="start_date" label="契約開始年月(YYYY-MM-DD)" defaultValue={startDate} inputRef={startDateRef} onChange={handleChangeStartDate}></TextField>
                <InputLabel id="autoPaymentInput" required>自動請求/手動請求</InputLabel>
                <Select labelId="autoPayment" id="select" label="自動請求/手動請求" defaultValue={autoPayment} required inputRef={autoPaymentRef} onChange={handleChangeAutoPayment}>
                  <MenuItem value={"auto"}>自動</MenuItem>
                  <MenuItem value={"manual"}>手動</MenuItem>
                </Select>
              </Stack>
            </Box>
            : <Loading />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>閉じる</Button>
          <Button type="submit" onClick={async () => handleSubmit()} disabled={false}>承認</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
