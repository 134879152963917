import { isNil, reduce, replace } from 'lodash/fp'
import axios, { AxiosHeaders, RawAxiosRequestHeaders, type Method } from 'axios'
import { inspect } from './inspect'
import log from 'loglevel'
import { assign } from 'lodash'

const env = process.env['REACT_APP_ENV'] ?? 'dev'

const baseName = 'terashite.jp'
const zoneName = `${{ prd: '', stg: '', int: 'dev.', dev: 'dev.', exp: 'dev.' }[env] as string
  }${baseName}`
export const domainName = `api${{ prd: '', stg: '-stg', int: '-int', dev: '', exp: '-exp' }[env] as string
  }.terashite-entry.${zoneName}`

export const trimSpace = (value: string): string => replace(/[　 ]/g, '')(value)

export const httpRequest = async (
  method: Method,
  url: string,
  body?: any,
  headers?: RawAxiosRequestHeaders | AxiosHeaders,
  params?: any
): Promise<{ status: number; data: any } | undefined> => {
  try {
    axios.interceptors.response.use(
      (response) => response,
      (error) => error.response
    )
    const config = {
      url,
      method
    }
    if (!isNil(body)) {
      assign(config, {
        data: body
      })
    }
    if (!isNil(headers)) {
      assign(config, {
        headers
      })
    }
    if (!isNil(params)) {
      assign(config, {
        params
      })
    }
    const response = await axios(config)
    const { status, data } = response
    return { status, data }
  } catch (error) {
    log.error(`[common.httpRequest] request failed`, inspect({ error }))
    return undefined
  }
}

