import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"
import { Box } from "@mui/material"
import { isNil } from "lodash/fp"
import log from "loglevel"
import { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import { Loading } from "../Loading"
import { UserAuthError } from "../UserAuthError"
import { authUser } from "./api"


const MyPage = () => {
    const { user } = useAuth0()
    const { isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect } = useAuth0()
    const [isBpoAuthenticated, setIsBpoAuthenticated] = useState<boolean>(false)
    const [memberId, setMemberId] = useState<string | undefined>(undefined)
    const [name, setName] = useState<string | undefined>(undefined)
    const [autoPayment, setAutoPayment] = useState<string | undefined>(undefined)

    useEffect(() => {
        const checkAuth = async () => {
            if (!isAuthenticated && !isLoading) {
                try {
                    await getAccessTokenSilently()
                } catch (error) {
                    loginWithRedirect()
                }
            }
        }
        checkAuth()
    }, [isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect, memberId])

    const sub = user?.sub as string
    if (isNil(memberId)) {
        authUser(sub).then((result: {
            success: boolean,
            memberId?: string,
            name?: string,
            autoPayment?: string
        }) => {
            if (!isNil(result) && result.success) {
                setMemberId(result.memberId)
                setName(result.name)
                setAutoPayment(result.autoPayment)
                setIsBpoAuthenticated(true)
            }
        }).catch(log.error)
    }

    return (
        <Box>
            {isLoading ? <Loading /> : isAuthenticated && isBpoAuthenticated ? <Outlet context={{ memberId, name, autoPayment }} /> : <UserAuthError />}
        </Box>
    )
}

export default withAuthenticationRequired(MyPage, { onRedirecting: () => (<Loading />) })