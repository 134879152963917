import { Buffer } from 'buffer';
import gmoKeys from '../RegistCreditCard/gmo-keys.json'
import { httpRequest } from "../../functions/common";
import { isNil } from "lodash/fp";

// @ts-ignore
window.Buffer = Buffer;


const env = process.env['REACT_APP_ENV'] ?? 'dev'

const baseName = 'miraiz-connect.jp'
const zoneName = `${{ prd: '', stg: '', int: 'dev.', dev: 'dev.', exp: 'dev.' }[env] as string
    }${baseName}`
const domainName = `api${{ prd: '', stg: '-stg', int: '-int', dev: '', exp: '-exp' }[env] as string
    }.bpo.${zoneName}`

const {
    id: shopId,
    domain
} = gmoKeys[env as keyof typeof gmoKeys]


export const getShopInfo = () => {
    return {
        domain,
        shopId
    }
}

export const createParams = async (memberId: string, companyName: string): Promise<string> => {
    const response = await httpRequest('GET', `https://${domainName}/v1/request/credit-regist/createparam?memberId=${memberId}&companyName=${companyName}`)
    if (isNil(response) || response.status !== 200) {
        throw new Error('Connect Failed.')
    }
    return response.data.param
}
