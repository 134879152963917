import { Box, Container, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material"
import { FormsProps } from "../../types"

export const RequestConfirm = (props: FormsProps) => {
    const { formData } = props
    const {
        companyName,
        departmentName,
        post,
        familyNameKanji,
        familyNameKana,
        firstNameKanji,
        firstNameKana,
        industry,
        phoneNumber,
        faxNumber,
        mailAddress,
        zipCode,
        prefecture,
        address1,
        address2,
        address3,
        questionnair,
        optionalAnswer,
    } = formData
    return (
        <Container maxWidth="sm" sx={{ pt: 5 }}>
            <Box>入力内容をご確認ください。</Box>
            <Box paddingBottom={3}>問題がない場合は「お申し込み」ボタンを押してください。</Box>
            <Box component="form" sx={{ mt: 1 }}>
                <Stack spacing={3}>
                    <TextField id="plan" label="プラン名称" value={"福利厚生プラン"} variant="filled" disabled></TextField>
                    <TextField id="corse" label="コース名称" value={"ビジエネコース"} variant="filled" disabled></TextField>
                    <TextField id="company_name" label="会社名" value={companyName} required variant="filled" disabled></TextField>
                    <TextField id="department_name" label="部署名" value={departmentName} required variant="filled" disabled></TextField>
                    <TextField id="post" label="役職名" value={post} required variant="filled" disabled></TextField>
                    <TextField id="family_name_kanji" label="担当者姓・漢字" value={familyNameKanji} required variant="filled" disabled></TextField>
                    <TextField id="first_name_kanji" label="担当者名・漢字" value={firstNameKanji} required variant="filled" disabled></TextField>
                    <TextField id="family_name_kana" label="担当者姓・カナ" value={familyNameKana} required variant="filled" disabled></TextField>
                    <TextField id="first_name_kana" label="担当者名・カナ" value={firstNameKana} required variant="filled" disabled></TextField>
                    <TextField id="industry" label="業種" value={industry} required variant="filled" disabled></TextField>
                    <TextField id="phone_number" label="連絡先電話番号" value={phoneNumber} required variant="filled" disabled></TextField>
                    <TextField id="fax_number" label="Fax番号" value={faxNumber} variant="filled" disabled></TextField>
                    <TextField id="mail_address" label="メールアドレス" value={mailAddress} required variant="filled" disabled></TextField>
                    <TextField id="zip_code" label="郵便番号" value={zipCode} variant="filled" disabled></TextField>
                    <TextField id="prefecture" label="都道府県" value={prefecture} required variant="filled" disabled></TextField>
                    <TextField id="address_1" label="市区町村" value={address1} required variant="filled" disabled></TextField>
                    <TextField id="address_2" label="番地" value={address2} required variant="filled" disabled></TextField>
                    <TextField id="address_3" label="アパート・マンション名・号室" value={address3} variant="filled" disabled></TextField>

                    <FormControl>
                        <InputLabel id="questionnair" required>アンケート</InputLabel>
                        <Select labelId="questionnair" value={questionnair} id="select" label="アンケート" defaultValue="none" required variant="filled" disabled>
                            <MenuItem value={"none"}></MenuItem>
                            <MenuItem value={"mzc_hp"}>中部電力ミライズコネクトホームページ</MenuItem>
                            <MenuItem value={"bizene_site"}>ビジエネサイト</MenuItem>
                            <MenuItem value={"bizene_mail"}>ビジエネメールマガジン</MenuItem>
                            <MenuItem value={"dm"}>ダイレクトメール</MenuItem>
                            <MenuItem value={"flyer"}>ポスティングチラシ</MenuItem>
                            <MenuItem value={"group_introduction"}>中部電力ミライズグループからの紹介</MenuItem>
                            <MenuItem value={"org_introduction"}>所属団体からの紹介</MenuItem>
                            <MenuItem value={"other"}>その他</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField id="optionalAnswer" label="アンケート記入欄" value={optionalAnswer} variant="filled" disabled></TextField>
                </Stack>
            </Box>
        </Container>
    )
}